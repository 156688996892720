import React from "react";
import "./App.scss";
import { Route, Switch } from "wouter";
import Application from "./pages/Application";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/application/:id" component={Application}></Route>
        <Route path="/congratulation"></Route>
        <Route path="/notfound">
          <div className="Application">
            <div className="status">找不到你的繳費通知</div>
            <div className="animate">
              <a
                className="button animate"
                href="https://www.ixda.org.tw/membership"
              >
                會員申請
              </a>
            </div>
          </div>
        </Route>
        <Route path="/">
          <div className="Application">
            <div className="status">我知道你想加入 IxDA Taiwan 會員</div>
            <div className="animate">
              <a
                className="button animate"
                href="https://www.ixda.org.tw/membership"
              >
                馬上行動
              </a>
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
