import { initializeApp } from "firebase/app";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";

import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAcplyPMhhaCxmP127M1SB4_6SnnseV0aM",
  authDomain: "member-d3a1f.firebaseapp.com",
  projectId: "member-d3a1f",
  storageBucket: "member-d3a1f.appspot.com",
  messagingSenderId: "717428927071",
  appId: "1:717428927071:web:608d15738c75a9548549ea",
};

export const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app, "asia-northeast1");
export const firestore = getFirestore(app);

if (process.env.NODE_ENV !== "production") {
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectFirestoreEmulator(firestore, "localhost", 8080);
}
export const CallNewebpay = httpsCallable(functions, "CallNewebpay");
