import { useEffect, useState, useRef, ReactNode } from "react";
import { RouteComponentProps } from "wouter";
import { firestore, CallNewebpay } from "../tools/firebase";
import { getDoc, doc } from "firebase/firestore";
import { useLocation } from "wouter";
import { ReactComponent as Badge } from "../logo/badge.svg";
import { ReactComponent as Copy } from "../logo/copy.svg";

function Application(props: RouteComponentProps<{ id: string }>) {
  const [application, setApplication] = useState({
    amount: 0,
    status: "initial",
    BankCode: "",
    CodeNo: "",
  });
  const [effect, setEffect] = useState("");
  const [copied, setCopied] = useState(false);
  const [location, setLocation] = useLocation();
  const formRef = useRef<HTMLFormElement>(null);
  const [formInput, setFormInput] = useState<ReactNode[]>([]);

  useEffect(() => {
    let ignore = false;
    const checkStatus = async () => {
      try {
        const docref = doc(firestore, "application", props.params.id);
        const docdata = (await getDoc(docref)).data();
        if (!docdata) {
          setLocation("/notfound");
        } else {
          // @ts-ignore
          setApplication(docdata);
          if (docdata.status === "finish") return;
          if (new Date() < new Date("2023-08-31T16:00:00.000Z")) {
            switch (docdata.status) {
              case "new":
              case "vacc":
                const formData = await CallNewebpay({
                  applicationId: props.params.id,
                });
                if (formRef.current !== null) {
                  formRef.current.method = "POST";
                  // @ts-ignore
                  formRef.current.action = formData.data.gateway;
                  setFormInput([
                    <input
                      key={0}
                      type="hidden"
                      name="MerchantID"
                      // @ts-ignore
                      value={formData.data.MerchantID}
                    ></input>,
                    <input
                      key={1}
                      type="hidden"
                      name="TradeInfo"
                      // @ts-ignore
                      value={formData.data.TradeInfo}
                    ></input>,
                    <input
                      key={2}
                      type="hidden"
                      name="TradeSha"
                      // @ts-ignore
                      value={formData.data.TradeSha}
                    ></input>,
                    <input
                      key={3}
                      type="hidden"
                      name="Version"
                      value="2.0"
                    ></input>,
                  ]);
                  window.setTimeout(() => {
                    if (formRef.current !== null) {
                      formRef.current.submit();
                    }
                  }, 1000);
                }
            }
          } else {
            setApplication({
              amount: 0,
              status: "expired",
              BankCode: "",
              CodeNo: "",
            });
            window.setTimeout(() => {
              setEffect("broken");
            }, 1000);
          }
        }
      } catch (ex) {
        console.log(ex);
      }
    };
    checkStatus();
    return () => {
      ignore = true;
    };
  }, [props.params.id, setLocation]);

  return (
    <div className="Application">
      <form ref={formRef} style={{ display: "none" }}>
        {formInput}
      </form>
      {(application.status === "initial" ||
        application.status === "new" ||
        application.status === "vacc") && (
        <>
          <div className="status">
            {application.status === "initial"
              ? "打開抽屜尋找你的申請書"
              : "帶你去繳會員費"}
          </div>
          <div className="animate">
            <div className="loading">
              <div className="loader">
                <div className="shadow"></div>
                <div className="box"></div>
              </div>
            </div>
          </div>
        </>
      )}
      {application.status === "finish" && (
        <>
          <div className="notes">
            <Badge className="flip" />
            <div style={{ marginTop: 50 }}>
              你已經繳費完成
              <br />
              恭喜你成為 IxDA 會員
              <br />
              會員通知信已經寄到你的 Email 信箱
            </div>
          </div>
        </>
      )}
      {application.status === "vacc2" && (
        <>
          <div className="notes">
            <Badge style={{ width: 91, height: 104 }} />
            <div style={{ marginTop: 50, textAlign: "left" }}>
              正在等待你使用 ATM 轉帳繳費
              <br />
              <br />
              銀行代號：{application.BankCode}
              <br />
              收款帳號：{application.CodeNo}
              <br />
              繳費金額：{application.amount}
              <br />
              繳費期限：2023年8月31日止
              <br />
              <div style={{ textAlign: "right" }}>
                <a
                  className={`button ${copied ? "copied" : ""}`}
                  onClick={() => {
                    setCopied(true);
                    navigator.clipboard
                      .writeText(
                        `IxDA Taiwan 會員繳費\r\n銀行代號：${application.BankCode} \r\n收款帳號：${application.CodeNo}\r\n繳費金額：${application.amount}\r\n繳費期限：2023年8月31日止止`
                      )
                      .then(
                        () => console.log("text copied"),
                        () => console.log("error copying text")
                      );
                  }}
                >
                  複製 <Copy />
                </a>
              </div>
            </div>
          </div>
        </>
      )}
      {application.status === "expired" && (
        <>
          <div className="notes">
            <div
              className={effect}
              style={{
                padding: "1em 2em",
                border: "5px solid #fff",
                backgroundColor: "rgba(255,255,255,0.2)",
                transition: "all 1s cubic-bezier(.29, 1.01, 1, -0.68)",
              }}
            >
              Expired
            </div>
            <div style={{ marginTop: 50, textAlign: "left" }}>
              繳費期限截止了
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Application;
